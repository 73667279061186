import React from 'react';
import { graphql } from 'gatsby';

import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/header';
import PageBanner from '../components/page-banner';
import ContactForm from '../components/Forms/ContactForm';
import BasicContent from '../components/basic-content';

class AboutPage extends React.Component {
  render() {
    const { data } = this.props;
    const { content } = data.allContentfulPage.edges[0].node;

    return (
      <Layout>
        <Header headerType="" />
        <SEO title="About" />
        <PageBanner />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumbs">
                <ul>
                  <li>
                    <AniLink fade className="home" to="/">
                      Home
                      <span>/</span>
                    </AniLink>
                  </li>
                  <li>
                    <AniLink fade className="current" to="/about">
                      About
                    </AniLink>
                  </li>
                </ul>
              </div>
              <h1>About us</h1>
            </div>
          </div>
        </div>
        {content.map(block => (
          <BasicContent content={block} />
        ))}
        <ContactForm
          heading="Start with a free quote!"
          subheading="One our team will be in contact to help you with your request. Obligation free."
          theme="grey"
          formType="project"
        />
      </Layout>
    );
  }
}

export default AboutPage;

export const aboutQuery = graphql`
  query {
    allContentfulPage(filter: { slug: { eq: "about" } }) {
      edges {
        node {
          id
          content {
            ... on ContentfulContentBlock {
              title
              childContentfulContentBlockTextRichTextNode {
                childContentfulRichText {
                  html
                }
              }
              image {
                id
                title
                fluid(maxWidth: 1600) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
