/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import get from 'lodash/get';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

class BasicContent extends Component {
  /**
   * Render image into the content block
   * if this has been set in Contentful
   * otherwise return null
   *
   * @param {Node} image
   */
  renderImage(image) {
    if (image) {
      return (
        <div className="page-banner-image content-block-image">
          <Img fluid={image.fluid} alt={image.title} className="fill-x" />
        </div>
      );
    }
    return null;
  }

  render() {
    const { content } = this.props;
    const text = get(
      content,
      'childContentfulContentBlockTextRichTextNode.childContentfulRichText.html'
    );

    return (
      <Fade delay={500}>
        <>
          <div className="container contact-us my3">
            <div className="row">
              <div className="col-12">
                <h4>{content.title}</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
                {this.renderImage(content.image)}
              </div>
            </div>
          </div>
        </>
      </Fade>
    );
  }
}

export default BasicContent;

BasicContent.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
};
